import * as yup from 'yup';
import { handleTimeZone } from 'utils/formatDate';
import moment from 'moment';

const dateValidation = (date: string): boolean => {
  const start = handleTimeZone(date);
  const today = handleTimeZone(new Date());

  const formats = 'YYYY-mm-dd';

  const startAt = moment(start, formats);
  const base = moment(today, formats);

  return base.isSameOrAfter(startAt);
};

const dateEndValidation = (ini: string, end: string): boolean => {
  if (!ini || !end) {
    return false;
  }
  const start = handleTimeZone(ini);
  const finish = handleTimeZone(end);

  const formats = 'YYYY-mm-dd';

  const startAt = moment(start, formats);
  const finishAt = moment(finish, formats);

  return finishAt.isSameOrAfter(startAt);
};

const generateValidation = (data: any): Map<any, any> => {
  const insert_mappedValidations = new Map();
  insert_mappedValidations.set(
    'combo',
    yup.string().notOneOf(['Selecione', '']),
  );
  insert_mappedValidations.set(
    'ev1i',
    yup.string().when('type', {
      is: (tp) => tp === 'CC',
      then: yup.string().when('gAmount', {
        is: (amount) => amount === '0',
        then: yup
          .string()
          .trim()
          .min(5, 'Quantidade mínima de caracteres não atingida (5).')
          .max(60, 'Quantidade máxima de caracteres excedida (60)'),
        otherwise: yup.string().nullable(true),
      }),
      otherwise: yup
        .string()
        .trim()
        .min(5, 'Quantidade mínima de caracteres não atingida (5).')
        .max(60, 'Quantidade máxima de caracteres excedida (60)'),
    }),
  );
  insert_mappedValidations.set(
    'ev2i',
    yup.string().when('type', {
      is: (tp) => tp === 'CC',
      then: yup.string().when('gAmount', {
        is: (amount) => amount !== '2',
        then: yup
          .string()
          .trim()
          .min(5, 'Quantidade mínima de caracteres não atingida (5).')
          .max(60, 'Quantidade máxima de caracteres excedida (60)'),
        otherwise: yup.string().nullable(true),
      }),
      otherwise: yup
        .string()
        .trim()
        .min(5, 'Quantidade mínima de caracteres não atingida (5).')
        .max(60, 'Quantidade máxima de caracteres excedida (60)'),
    }),
  );
  insert_mappedValidations.set(
    'ev1s',
    yup.string().when('type', {
      is: (tp) => tp === 'CC',
      then: yup.string().when('gAmount', {
        is: (amount) => amount !== '0',
        then: yup.string().notOneOf(['Selecione', '']),
        otherwise: yup.string().notRequired(),
      }),
      otherwise: yup.string().notOneOf(['Selecione', '']),
    }),
  );
  insert_mappedValidations.set(
    'ev2s',
    yup.string().when('type', {
      is: (tp) => tp === 'CC',
      then: yup.string().when('gAmount', {
        is: (amount) => amount === '2',
        then: yup.string().notOneOf(['Selecione', '']),
        otherwise: yup.string().notRequired(),
      }),
      otherwise: yup.string().notOneOf(['Selecione', '']),
    }),
  );
  insert_mappedValidations.set(
    'dtinc',
    yup
      .date()
      .typeError('Data inválida')
      .test('dtincTest', 'Data deve ser anterior à data atual.', (date) => {
        return dateValidation(date);
      }),
  );
  insert_mappedValidations.set(
    'dtend',
    yup
      .date()
      .typeError('Data inválida')
      .test(
        'check-with-dtinc',
        'Data deve ser posterior à data de inclusão',
        (date) => {
          return dateEndValidation(data.dtinc, date);
        },
      ),
  );
  return insert_mappedValidations;
};

export { generateValidation, dateValidation };
