import React, { HTMLAttributes, useState, useCallback, useEffect } from 'react';

import { useHistory, useLocation, Link } from 'react-router-dom';

import {
  FaArrowLeft,
  FaPowerOff,
  FaChevronDown,
  FaFilter,
  FaSearch,
  FaWhatsapp,
} from 'react-icons/fa';

import Loading from 'components/Loading';
import * as S from 'styles/dialog_consult';
import { ShowProps } from 'styles/dialog_consult';
import * as D from 'styles/dialog_delete';
import { DeleteProps, ModalDeleteContent } from 'styles/dialog_delete';
import { useWindow } from 'hooks/window';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useCredentials } from 'hooks/credentials';
import api from 'services/api';
import {
  Container,
  BurgerContainer,
  Burger,
  BurgerContent,
  BackContainer,
  PazinatoContainer,
  SupportContainer,
  LogoutContainer,
  NavContent,
  Menu,
  BurgerItem,
  Filter,
  FilterContent,
} from './styles';

interface SGONavbarProps extends HTMLAttributes<HTMLDivElement> {
  containerType?: string;
  containerStyle?: object;
  noLinks?: boolean;
  title?: string;
  needFilter?: boolean;
  filterContent?: JSX.Element;
  isResponsible?: boolean;
  disableBack?: boolean;
  customBack?: JSX.Element | boolean;
}

const SGONavbar: React.FC<SGONavbarProps> = ({
  containerStyle = {},
  containerType = 'links',
  noLinks = false,
  title = '',
  needFilter = false,
  filterContent,
  isResponsible = false,
  disableBack = false,
  customBack,
}) => {
  const [supportKey] = useState(() => {
    return process.env.REACT_APP_SUPPORT || '';
  });
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [contact, setContact] = useState(() => {
    const existent = localStorage.getItem(supportKey);
    if (existent) {
      return existent;
    }
    return '';
  });

  const [subMenu, setSubMenu] = useState(() => {
    const sub = pathname.replace('/sgo/', '').substr(0, 3);
    if (sub) {
      return sub;
    }
    return 'mat';
  });
  const { width } = useWindow();
  const { user, signOut } = useAuth();
  const { addToast } = useToast();
  const { errorHandling } = useCredentials();

  const [year] = useState(new Date().getUTCFullYear());
  const [semester] = useState(() => {
    const month = new Date().getUTCMonth() + 1;

    return month < 8 ? 1 : 2;
  });

  const [show, setShow] = useState<ShowProps>({} as ShowProps);

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({
    open: false,
    content: '',
    values: {},
  });

  const { goBack } = useHistory();
  const { adm, ger, mat, sec, tes } = user;
  const history = useHistory();

  const getSupportContact = useCallback(async () => {
    try {
      const locallyStored = localStorage.getItem(supportKey);
      const response = await api.get('/common/support.php');
      if (response.data) {
        const { PHONE } = response.data;
        if (!locallyStored || (!!locallyStored && PHONE !== locallyStored)) {
          setContact(PHONE);
          localStorage.setItem(supportKey, PHONE);
        }
        // TODO: store on local storage.
      }
    } catch (e) {
      console.log(e);
    }
  }, [supportKey]);

  // const handleSuporteCall = useCallback(() => {
  //   history.push('/sgo/suporte');
  // }, [history]);

  const handleVideoaulaCall = useCallback(() => {
    history.push('/sgo/videoaulas');
  }, [history]);

  // const handleADMIN = useCallback(() => {
  //   history.push('/sgo/admin');
  // }, [history]);

  // const handleVersion = useCallback(() => {
  //   history.push('/sgo/version');
  // }, [history]);

  const handleLoadStatus = useCallback(() => {
    setDeleteDiag((state) => ({ ...state, open: !state.open }));
  }, []);

  const handleLoadConfirm = useCallback(async () => {
    try {
      setLoading(true);
      const send = new FormData();
      send.append('data', JSON.stringify({ year, semester }));
      setDeleteDiag((state) => ({ ...state, open: !state.open }));

      await api.post('/fechamento/load.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setLoading(false);

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: `Carga ${semester}° semestre de ${year} realizada.`,
      });
    } catch (err) {
      errorHandling(err);
    }
  }, [addToast, errorHandling, semester, year]);

  const handleChanges = useCallback(() => {
    if (containerType === 'buttons') {
      window.scrollTo(0, 0);
      setIsOpen((state) => !state);
    }
  }, [containerType]);

  const handleSubMenu = useCallback(
    (value: string) => {
      setSubMenu(value === subMenu ? '' : value);
    },
    [subMenu],
  );

  const handleBurgerOpen = useCallback(() => {
    setIsOpen((state) => !state);
    setOpenFilter(false);

    if (isOpen) {
      setSubMenu('');
    }
  }, [isOpen]);

  const handleFilterOpen = useCallback(() => {
    setIsOpen(false);
    setOpenFilter((state) => !state);
  }, []);

  const handleGoBack = useCallback(() => {
    goBack();
  }, [goBack]);

  const handleInconsistencia = useCallback(() => {
    setShow((state) => ({ ...state, open: true }));
    const timer = setTimeout(() => {
      setShow((state) => ({
        ...state,
        open: false,
      }));
    }, 5000);

    const link = document.querySelector<HTMLAnchorElement>(`a[id=INCON]`);
    if (link) {
      link.click();
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    getSupportContact();
  }, [getSupportContact]);

  return (
    <Container style={containerStyle} isResponsible={isResponsible}>
      <Loading isLoading={loading} />
      {!disableBack && (
        <BackContainer>
          {customBack || <FaArrowLeft onClick={handleGoBack} />}
        </BackContainer>
      )}
      {title && <h2>{title}</h2>}
      {width > 900 ? (
        <NavContent disableLinks={noLinks}>
          {mat.enabled && user.status && mat.content.length > 0 && (
            <Menu quantity={mat.content.length}>
              <h3>Material</h3>
              <span>
                {mat.content.map((item) => (
                  <Link key={`nav${item.route}`} to={`/sgo/${item.route}`}>
                    {item.text}
                  </Link>
                ))}
              </span>
            </Menu>
          )}
          {tes.enabled && user.status && tes.content.length > 0 && (
            <Menu quantity={tes.content.length}>
              <h3>Tesouraria</h3>
              <span>
                {tes.content.map((item) => (
                  <Link key={`nav${item.route}`} to={`/sgo/${item.route}`}>
                    {item.text}
                  </Link>
                ))}
              </span>
            </Menu>
          )}
          {sec.enabled && user.status && sec.content.length > 0 && (
            <Menu quantity={sec.content.length}>
              <h3>Secretaria</h3>
              <span>
                {sec.content.map((item) => (
                  <Link key={`nav${item.route}`} to={`/sgo/${item.route}`}>
                    {item.text}
                  </Link>
                ))}
              </span>
            </Menu>
          )}
          {ger.enabled && user.status && ger.content.length > 0 && (
            <Menu
              quantity={
                ['NAC', 'LOC'].indexOf(user.perfil) > -1
                  ? ger.content.length + 1
                  : ger.content.length
              }
            >
              <h3>Gerencial</h3>
              <span>
                {ger.content.map((item) => (
                  <Link key={`nav${item.route}`} to={`/sgo/${item.route}`}>
                    {item.text}
                  </Link>
                ))}
                {['NAC', 'LOC'].indexOf(user.perfil) > -1 && (
                  <button type="button" onClick={handleInconsistencia}>
                    Controle de Qualidade
                  </button>
                )}
              </span>
            </Menu>
          )}
          {user.perfil !== 'INT' && (
            <Menu onClick={handleVideoaulaCall}>
              <h3>Videoaula</h3>
            </Menu>
          )}
          {/* <Menu onClick={handleSuporteCall}>
            <h3>Suporte</h3>
          </Menu> */}
          {adm && (
            <Menu quantity={7} adm>
              <h3>
                <PazinatoContainer>
                  <img
                    src={`${process.env.REACT_APP_ASSETS_DIR}/header/Logo_Pazinato_Branco_FundoTransp_100x100.png`}
                    alt="Admin"
                  />
                </PazinatoContainer>
              </h3>
              <span>
                <Link to="/sgo/admin">Admin</Link>
                <Link to="/sgo/admin/php">Upload PHP</Link>
                <Link to="/sgo/admin/faturamento">Faturamento</Link>
                <Link to="/sgo/admin/conciliacao">Conciliação</Link>
                <Link to="/sgo/admin/reset_user">Resetar Usuário</Link>
                <Link to="/sgo/admin/hour_testing">Teste Horários</Link>
                <button type="button" onClick={handleLoadStatus}>
                  Cargas Semestrais
                </button>
              </span>
            </Menu>
          )}
          {/* {parseInt(user.gseq, 10) === 3 && (
            <Menu onClick={handleVersion}>
              <h3>Version</h3>
            </Menu>
          )} */}
        </NavContent>
      ) : (
        <BurgerContainer disableLinks={noLinks}>
          <Burger isOpen={isOpen} onClick={handleBurgerOpen}>
            <div />
          </Burger>

          <BurgerContent isOpen={isOpen} onClick={handleChanges}>
            {mat.enabled && user.status && (
              <BurgerItem
                quantity={mat.content.length}
                isActive={subMenu === 'mat'}
              >
                <h3 onClick={() => handleSubMenu('mat')}>
                  <FaChevronDown /> Material
                </h3>
                <span>
                  {mat.content.map((item, index) => {
                    return (
                      <Link
                        key={`burger${item.route}`}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#fff' : '#fbf4f4',
                        }}
                        to={`/sgo/${item.route}`}
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                </span>
              </BurgerItem>
            )}
            {tes.enabled && user.status && (
              <BurgerItem
                quantity={tes.content.length}
                isActive={subMenu === 'tes'}
              >
                <h3 onClick={() => handleSubMenu('tes')}>
                  <FaChevronDown /> Tesouraria
                </h3>
                <span>
                  {tes.content.map((item, index) => {
                    return (
                      <Link
                        key={`burger${item.route}`}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#fff' : '#fbf4f4',
                        }}
                        to={`/sgo/${item.route}`}
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                </span>
              </BurgerItem>
            )}
            {sec.enabled && user.status && (
              <BurgerItem
                quantity={sec.content.length}
                isActive={subMenu === 'sec'}
              >
                <h3 onClick={() => handleSubMenu('sec')}>
                  <FaChevronDown /> Secretaria
                </h3>
                <span>
                  {sec.content.map((item, index) => {
                    return (
                      <Link
                        key={`burger${item.route}`}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#fff' : '#fbf4f4',
                        }}
                        to={`/sgo/${item.route}`}
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                </span>
              </BurgerItem>
            )}
            {ger.enabled && user.status && (
              <BurgerItem
                quantity={ger.content.length + 1}
                isActive={subMenu === 'ger'}
              >
                <h3 onClick={() => handleSubMenu('ger')}>
                  <FaChevronDown /> Gerencial
                </h3>
                <span>
                  {ger.content.map((item, index) => {
                    return (
                      <Link
                        key={`burger${item.route}`}
                        style={{
                          backgroundColor: index % 2 === 0 ? '#fff' : '#fbf4f4',
                        }}
                        to={`/sgo/${item.route}`}
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                  {['NAC', 'LOC'].indexOf(user.perfil) > -1 && (
                    <button type="button" onClick={handleInconsistencia}>
                      Controle de Qualidade
                    </button>
                  )}
                </span>
              </BurgerItem>
            )}
            <BurgerItem>
              <h3 onClick={handleVideoaulaCall}>Videoaula</h3>
            </BurgerItem>
            {adm && (
              <BurgerItem quantity={6} isActive={subMenu === 'adm'}>
                <h3 onClick={() => handleSubMenu('adm')}>
                  <PazinatoContainer>
                    <img
                      src={`${process.env.REACT_APP_ASSETS_DIR}/header/Logo_Pazinato_Branco_FundoTransp_100x100.png`}
                      alt="Admin"
                    />
                  </PazinatoContainer>
                </h3>
                <span>
                  <Link to="/sgo/admin">Admin</Link>
                  <Link to="/sgo/admin/php">Upload PHP</Link>
                  <Link to="/sgo/admin/faturamento">Faturamento</Link>
                  <Link to="/sgo/admin/conciliacao">Conciliação</Link>
                  <Link to="/sgo/admin/reset_user">Resetar Usuário</Link>
                  <button type="button" onClick={handleLoadStatus}>
                    Cargas Semestrais
                  </button>
                </span>
              </BurgerItem>
            )}
            {/* {parseInt(user.gseq, 10) === 3 && (
              <BurgerItem onClick={handleVersion}>
                <h3>Version</h3>
                <span />
              </BurgerItem>
            )} */}
          </BurgerContent>
        </BurgerContainer>
      )}
      {needFilter && filterContent && (
        <>
          <Filter>
            <FaFilter onClick={handleFilterOpen} />
          </Filter>

          <FilterContent isOpen={openFilter}>
            {/* <FilterContent isOpen> */}
            {filterContent}
            <button
              type="button"
              onClick={() => setOpenFilter((state) => !state)}
            >
              <FaSearch />
            </button>
          </FilterContent>
        </>
      )}
      {contact.length > 0 && (
        <SupportContainer>
          <a
            // href={`https://api.whatsapp.com/send?phone=+393475987431&text=Olá!%0aSou *${user.name}*%0a\`\`\`Certificado:\`\`\` *${user.gcert}*%0a\`\`\`Local:\`\`\` *${user.loccod}-${user.locdesc}*%0a\`\`\`Nacional:\`\`\` *${user.anbc}-${user.anbdesc}*%0a\`\`\`Perfil:\`\`\` *${user.perfildesc}*%0a%0a\`\`\`Motivo do Contato:\`\`\`%0a%0a`}
            href={`https://api.whatsapp.com/send?phone=${contact}&text=Olá!%0aSou *${user.name}*%0a\`\`\`Certificado:\`\`\` *${user.gcert}*%0a\`\`\`Local:\`\`\` *${user.loccod}-${user.locdesc}*%0a\`\`\`Nacional:\`\`\` *${user.anbc}-${user.anbdesc}*%0a\`\`\`Perfil:\`\`\` *${user.perfildesc}*%0a%0a\`\`\`Motivo do Contato:\`\`\`%0a%0a`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FaWhatsapp />
          </a>
        </SupportContainer>
      )}
      <LogoutContainer data-name="logout">
        <FaPowerOff onClick={signOut} title="Encerrar sessão" />
      </LogoutContainer>
      <S.Container scroll="paper" maxWidth={false} open={!!show.open}>
        <S.Title>
          <h2>{show.title || 'Consulta'}</h2>
        </S.Title>
        <S.Content>
          <div>
            <p>O arquivo já está sendo gerado!</p>
            <p>Assim que estiver pronto o download começará automaticamente.</p>
            <p>Aguarde...</p>
          </div>
        </S.Content>
        <S.Actions>
          <S.Confirm
            type="button"
            onClick={() => setShow({ ...show, open: false })}
          >
            Ok
          </S.Confirm>
        </S.Actions>
      </S.Container>
      <D.Container scroll="paper" maxWidth={false} open={deleteDiag.open}>
        <D.Title>
          <h2>*** ATENÇÃO ***</h2>
        </D.Title>
        <D.Content>
          <ModalDeleteContent>
            Ao clicar em confirmar você executará a carga semestral para:
            <div>
              <span>
                <p>
                  Ano: <strong>{year}</strong>
                </p>
              </span>
              <span>
                <p>
                  Semestre: <strong>{semester}° semestre</strong>
                </p>
              </span>
            </div>
            Se estiver seguro de sua decisão, clique em confirmar para
            continuar.
          </ModalDeleteContent>
        </D.Content>
        <D.Actions>
          <D.Cancel type="button" onClick={handleLoadStatus}>
            Cancelar
          </D.Cancel>
          <D.Confirm type="button" onClick={handleLoadConfirm}>
            Confirmar
          </D.Confirm>
        </D.Actions>
      </D.Container>
      <a
        style={{ display: 'none' }}
        id="INCON"
        href={`${
          process.env.REACT_APP_API
        }/sgo/xls_inconsistencia.php?data=${JSON.stringify({
          token: localStorage.getItem(`${process.env.REACT_APP_TOKEN}`),
        })}`}
        rel="noreferrer noopener"
        target="_top"
      >
        Controle de Qualidade
      </a>
    </Container>
  );
};

export default SGONavbar;
