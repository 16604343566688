import React, { useState, useEffect, useCallback, useRef } from 'react';

import {
  Container,
  Content,
  ComboProps,
  ComboLocalProps,
} from 'styles/sgo_wrappers';
import { useHistory, useLocation } from 'react-router-dom';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import ScrollTop from 'components/ScrollTop';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import Input from 'components/Input';
import Button from 'components/Button';
import SelectV2 from 'components/SelectV2';

import api from 'services/api';

import { useCredentials } from 'hooks/credentials';
import { useAuth } from 'hooks/auth';
import { useToast } from 'hooks/toast';
import { useUtils } from 'hooks/utils';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import getValidationErrors from 'utils/getValidationErrors';

import * as yup from 'yup';
import { convertSpecialChars } from 'utils/specialChars';
import { LeftContainer, RightContainer, InfoContainer } from './styles';

interface OptionsStateProps {
  options: {
    seq: string;
    type: string;
    tscod: string;
    cadastrados?: string[];
  };
}

interface StateProps {
  seq: string;
  type: string;
  tscod: string;
  cadastrados?: string[];
}

interface FormData {
  name: string;
  fres: string;
  fcel: string;
  dtnasc: string;
  mail: string;
  cep: string;
  state: string;
  address: string;
  number: string;
  compl: string;
  district: string;
  city: string;
  name_wife?: string;
  mail_wife?: string;
}

interface FormData2 {
  guia: string;
}

interface ComboGuiaProps {
  seq: string;
  cert: string;
  nome: string;
  anb: string;
  loc: string;
  tg: string;
  func: string;
}

interface InitProps {
  zon: string;
  anb: string;
  loc: string;
  label: string;
}

const ParticipantesInsert: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRef2 = useRef<FormHandles>(null);
  const location = useLocation<OptionsStateProps>();
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { getCEP } = useUtils();
  const { errorHandling } = useCredentials();
  const [loading, setLoading] = useState(false);

  const [comboEstados, setComboEstados] = useState<ComboProps[]>([]);

  const [cadastrados] = useState<string[]>(() => {
    if (!location.state.options.cadastrados) {
      return [];
    }
    return location.state.options.cadastrados;
  });

  const [firstUF, setFirstUF] = useState('Selecione');
  const [hasAddress, setHasAddress] = useState(false);
  const [hasDistrict, setHasDistrict] = useState(false);
  const [hasCity, setHasCity] = useState(false);
  const [hasState, setHasState] = useState(false);

  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [guias, setGuias] = useState<ComboGuiaProps[]>([]);
  const [comboGuias, setComboGuias] = useState<ComboProps[]>([]);

  const [init, setInit] = useState<InitProps>({
    zon: user.zoncod,
    anb: user.anbc,
    loc: user.loccod,
    label: 'Selecione',
  });

  const [at] = useState<StateProps>(() => {
    if (!location.state) {
      return {} as StateProps;
    }

    const received = location.state.options || ({} as OptionsStateProps);

    if (!received) {
      return {} as StateProps;
    }
    console.log(received);
    const { type, seq, tscod } = received;

    return { type, seq, tscod };
  });

  const getUFs = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/combos/comboUFs.php');

      setComboEstados(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling]);

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');

    setComboZons(response.data);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);
    setComboNacs(
      response.data.filter(
        (item: ComboProps) =>
          item.value.substr(0, 2) === user.zoncod.substr(0, 2),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    setComboLocs(
      response.data.filter((item: ComboLocalProps) => item.anb === user.anbc),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGuias = useCallback(
    async (param?: string) => {
      let filterOBJ = {
        filter: 'byLoc',
        loccod: param || user.loccod,
      } as any;

      if (['ED', 'RE'].includes(at.tscod)) {
        filterOBJ = {
          filter: 'byLoc',
          loccod: param || user.loccod,
          filterForSERV: true,
          svcId: at.seq,
        } as any;
      }

      const response = await api.get(
        `/combos/comboGuias.php?data=${JSON.stringify(filterOBJ)}`,
      );

      setGuias(response.data);

      setComboGuias(
        response.data
          .filter(
            (item: ComboGuiaProps) =>
              ['GA', 'GP'].indexOf(item.tg) >= 0 &&
              cadastrados.indexOf(item.seq) < 0,
          )
          .map((item: ComboGuiaProps) => ({
            value: item.seq,
            label: item.nome,
          })),
      );
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const initSType = useCallback(() => {
    getComboZON();
    getComboANB();
    getComboLOC();
    getGuias();

    formRef2.current?.setData({
      zon: user.zoncod,
      nac: user.anbc,
      loc: user.loccod,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !(
        location.pathname.includes('atividades') ||
        location.pathname.includes('servicos')
      )
    ) {
      history.replace(
        `${location.pathname.substring(
          0,
          location.pathname.indexOf('secretaria') + 10,
        )}/not-found`,
      );
    }

    if (!location.state) {
      history.goBack();
    }

    if (at.type === 'S') {
      initSType();
    }

    getUFs();
  }, [
    at,
    initSType,
    // getGuias,
    // getComboLOC,
    // getComboANB,
    // getComboZON,
    getUFs,
    history,
    location.pathname,
    location.state,
    user.zoncod,
    user.anbc,
    user.loccod,
    cadastrados,
  ]);

  const handleCep = useCallback(async () => {
    const form = formRef.current;

    if (form) {
      const cepInput: string = form.getFieldValue('cep');

      if (cepInput.length === 9) {
        const { address, city, district, state } = await getCEP(
          cepInput.replace(/\D/g, ''),
        );

        if (state && state.length > 0) {
          const index = comboEstados.findIndex(
            (uf: ComboProps) => uf.value === state,
          );

          setFirstUF(comboEstados[index].value);
          setHasState(true);
        } else {
          setFirstUF('Selecione');
        }

        if (address && address.length > 0) {
          setHasAddress(true);
        }
        if (city && city.length > 0) {
          setHasCity(true);
        }
        if (district && district.length > 0) {
          setHasDistrict(true);
        }

        form.setData({
          address: (address && address.split(/[-,]/, 1)) || '',
          city: city || '',
          district: district || '',
          state: state || '',
          ...form,
        });
      } else {
        setFirstUF('Selecione');
        setHasAddress(false);
        setHasCity(false);
        setHasDistrict(false);

        form.setData({
          address: '',
          city: '',
          district: '',
          state: '',
          ...form,
        });
      }
    }
  }, [comboEstados, getCEP]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).'),
          fres: yup.string().nullable(true),
          fcel: yup.string().nullable(true),
          dtnasc: yup
            .date()
            .nullable()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .notRequired(),
          mail: yup.lazy((val) =>
            val
              ? yup.string().email('Formato inválido de e-mail')
              : yup.string().nullable(true),
          ),
          cep: yup.string().min(9, 'O CEP deve conter 9 caracteres.'),
          state: yup.string().nullable(true),
          address: yup.lazy((val) =>
            val
              ? yup
                  .string()
                  .trim()
                  .min(4, 'Quantidade mínima de caracteres não atingida (4).')
                  .max(70, 'Quantidade máxima de caracteres excedida (70).')
              : yup.string().nullable(true),
          ),
          number: yup.string().nullable(true),
          compl: yup.string().trim().nullable(true),
          district: yup.string().trim().nullable(true),
          city: yup.string().trim().nullable(true),
        });

        const schemaCC = yup.object().shape({
          name: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).'),
          name_wife: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).'),
          dtnasc: yup.date().typeError('Data inválida.'),
          cep: yup.string().min(9, 'O CEP deve conter 9 caracteres.'),
          address: yup.lazy((val) =>
            val
              ? yup
                  .string()
                  .trim()
                  .min(4, 'Quantidade mínima de caracteres não atingida (4).')
                  .max(70, 'Quantidade máxima de caracteres excedida (70).')
              : yup.string().nullable(true),
          ),
          number: yup.string().required('Este campo é obrigatório.'),
          compl: yup.string().trim().nullable(true),
          district: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).'),
          city: yup
            .string()
            .trim()
            .min(3, 'Quantidade mínima de caracteres não atingida (3).'),
        });

        if (at.tscod === 'CC') {
          await schemaCC.validate(data, {
            abortEarly: false,
          });
        } else {
          await schema.validate(data, {
            abortEarly: false,
          });
        }

        const newInfo = {
          ...data,
          name: convertSpecialChars(
            at.tscod === 'CC' ? `${data.name} e ${data.name_wife}` : data.name,
          ),
          address:
            convertSpecialChars(data.address) || 'Endereço não informado',
          district: convertSpecialChars(data.district),
          city: convertSpecialChars(data.city),
          number: data.number || 'S/N',
          seq: at.seq,
          type: at.type,
          mail:
            at.tscod === 'CC' ? `${data.mail} / ${data.mail_wife}` : data.mail,
        };

        // await api.get(
        //   `/sgo/atividades_participantes_insert.php?data=${JSON.stringify(
        //     send,
        //   )}`,
        // );

        const send = new FormData();
        send.append('data', JSON.stringify(newInfo));

        await api.post('/sgo/atividades_participantes_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `${at.tscod === 'CC' ? 'Casal' : 'Oficinista'} ${
            newInfo.name
          } incluído!`,
        });

        history.goBack();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },
    [addToast, at.seq, at.tscod, at.type, errorHandling, history],
  );

  const handleSubmit2 = useCallback(
    async (data: FormData2) => {
      try {
        setLoading(true);
        formRef2.current?.setErrors({});

        const schema = yup.object().shape({
          guia: yup.string().notOneOf(['Selecione', '']),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const index = comboGuias.findIndex(
          (item: ComboProps) => item.value === data.guia,
        );

        const newInfo = {
          ...data,
          name: comboGuias[index].label,
          type: at.type,
          seq: at.seq,
        };

        const send = new FormData();
        send.append('data', JSON.stringify(newInfo));

        await api.post('/sgo/atividades_participantes_insert.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        const newGuias = guias
          .filter(
            (item: ComboGuiaProps) =>
              item.seq !== data.guia &&
              ['GA', 'GP'].indexOf(item.tg) >= 0 &&
              cadastrados.indexOf(item.seq) < 0,
          )
          .map((item: ComboGuiaProps) => ({
            value: item.seq,
            label: item.nome,
          }));

        cadastrados.push(data.guia);
        // setCadastrados((state) => [...state, data.guia]);

        if (comboGuias.length > 1) {
          const newValue =
            comboGuias[
              index + 1 >= comboGuias.length
                ? index - 1 < 0
                  ? index + 1
                  : index - 1
                : index + 1
            ].value;
          const newLabel =
            comboGuias[
              index + 1 >= comboGuias.length
                ? index - 1 < 0
                  ? index + 1
                  : index - 1
                : index + 1
            ].label;

          formRef2.current?.setFieldValue('guia', newValue);
          setInit((state) => ({ ...state, glabel: newLabel }));

          setComboGuias(
            comboGuias.length <= 1
              ? [{ value: '', label: 'Selecione' }, ...newGuias]
              : newGuias,
          );
        } else {
          formRef2.current?.setFieldValue('guia', '');
          setInit((state) => ({ ...state, glabel: 'Selecione' }));
        }

        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: `Guia ${newInfo.name} adicionado à atividade!`,
        });

        if (comboGuias.length === 1) {
          setComboGuias([]);
          formRef2.current?.setFieldValue('guia', '');

          if (['JA', 'GC', 'CG'].indexOf(at.tscod) < 0) {
            setInit((state) => ({
              ...state,
              glabel: 'Selecione de outra local',
            }));
          } else {
            history.goBack();
          }
        }

        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err instanceof yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef2.current?.setErrors(errors);
          return;
        }
        errorHandling(err);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, at.seq, at.type, comboGuias, errorHandling],
  );

  const handleZONSelect = useCallback(() => {
    setLoading(true);
    const zon = formRef2.current?.getFieldValue('zon');
    const index = comboZons.findIndex((item: ComboProps) => item.value === zon);

    const newNacs = nacs.filter(
      (item: ComboProps) => item.value.substr(0, 2) === zon.substr(0, 2),
    );
    setComboNacs(newNacs);

    const newLocs = locs.filter(
      (item: ComboLocalProps) => item.anb === newNacs[0].value,
    );
    setComboLocs(newLocs);

    getGuias(newLocs[0].value);

    setInit({
      zon: comboZons[index].value,
      anb: newNacs[0].value,
      loc: newLocs[0].value,
      label: 'Selecione',
    });

    formRef2.current?.setFieldValue('zon', zon);
    formRef2.current?.setFieldValue('nac', newNacs[0].value);
    formRef2.current?.setFieldValue('loc', newLocs[0].value);
  }, [comboZons, getGuias, locs, nacs]);

  const handleNACSelect = useCallback(() => {
    setLoading(true);
    const nac = formRef2.current?.getFieldValue('nac');
    const index = comboNacs.findIndex((item: ComboProps) => item.value === nac);

    const newLocs = locs.filter(
      (item: ComboLocalProps) => item.anb === comboNacs[index].value,
    );
    setComboLocs(newLocs);

    getGuias(newLocs[0].value);

    setInit({
      ...init,
      anb: comboNacs[index].value,
      loc: newLocs[0].value,
      label: 'Selecione',
    });

    formRef2.current?.setFieldValue('nac', nac);
    formRef2.current?.setFieldValue('loc', newLocs[0].value);
  }, [comboNacs, getGuias, init, locs]);

  const handleLOCSelect = useCallback(() => {
    setLoading(true);
    const loc = formRef2.current?.getFieldValue('loc');
    const index = comboLocs.findIndex((item: ComboProps) => item.value === loc);

    getGuias(comboLocs[index].value);

    setInit({
      ...init,
      loc: comboLocs[index].value,
      label: 'Selecione',
    });

    formRef2.current?.setFieldValue('loc', loc);
    formRef2.current?.setFieldValue('guia', '');
  }, [comboLocs, getGuias, init]);

  return (
    <Container>
      <Loading isLoading={loading} />

      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        noLinks
        title={`Cadastro ${at.type === 'O' ? 'Oficinista' : 'Guia'} | ${
          at.type
        }-${at.seq}`}
      />
      <Content>
        {at.type === 'O' ? (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InfoContainer>
              <LeftContainer delay={0.1}>
                <span>
                  <p>Nome{at.tscod === 'CC' ? ' do Esposo' : ''}</p>
                  <Input
                    name="name"
                    mask="name"
                    placeholder={`Nome${at.tscod === 'CC' ? ' do Esposo' : ''}`}
                  />
                </span>
                {at.tscod === 'CC' && (
                  <span>
                    <p>Nome da Esposa</p>
                    <Input
                      name="name_wife"
                      mask="name"
                      placeholder="Nome da Esposa"
                    />
                  </span>
                )}
                <span>
                  <p>{at.tscod === 'CC' ? 'Celular do Esposo' : 'Telefone'}</p>
                  <Input
                    name="fres"
                    mask="phone"
                    placeholder={
                      at.tscod === 'CC' ? '(XX) XXXXX-XXXX' : '(XX) XXXX-XXXX'
                    }
                    maxLength={at.tscod === 'CC' ? 15 : 14}
                  />
                </span>
                <span>
                  <p>{at.tscod === 'CC' ? 'Celular da Esposa' : 'Celular'}</p>
                  <Input
                    name="fcel"
                    mask="phone"
                    placeholder="(XX) XXXXX-XXXX"
                    maxLength={15}
                  />
                </span>
                <span>
                  <p>E-Mail{at.tscod === 'CC' ? ' Esposo' : ''}</p>
                  <Input name="mail" placeholder="E-Mail" />
                </span>
                {at.tscod === 'CC' && (
                  <span>
                    <p>E-Mail da Esposa</p>
                    <Input name="mail_wife" placeholder="E-Mail" />
                  </span>
                )}
                <span>
                  <p>
                    Data de {at.tscod === 'CC' ? 'Matrimônio' : 'Nascimento'}
                  </p>
                  <Input name="dtnasc" isDate placeholder="dd/mm/aaaa" />
                </span>
              </LeftContainer>
              <RightContainer delay={0.35}>
                <span>
                  <p>CEP</p>
                  <Input
                    name="cep"
                    placeholder="N° CEP"
                    mask="cep"
                    onChange={handleCep}
                  />
                </span>
                <span>
                  <p>Estado</p>
                  <SelectV2
                    name="state"
                    content={comboEstados}
                    initial={firstUF}
                    disabled={hasState}
                  />
                </span>
                <span>
                  <p>Endereço</p>
                  <Input
                    name="address"
                    placeholder="Endereço"
                    disabled={hasAddress}
                  />
                </span>
                <span>
                  <p>Numero</p>
                  <Input name="number" placeholder="S/N" />
                </span>
                <span>
                  <p>Complemento</p>
                  <Input name="compl" placeholder="Complemento" mask="alpha" />
                </span>
                <span>
                  <p>Bairro</p>
                  <Input
                    name="district"
                    placeholder="Bairro"
                    disabled={hasDistrict}
                    mask="alpha"
                  />
                </span>
                <span>
                  <p>Cidade</p>
                  <Input
                    name="city"
                    placeholder="Cidade"
                    disabled={hasCity}
                    mask="alpha"
                  />
                </span>
              </RightContainer>
            </InfoContainer>

            <Button
              bgcolor="#00802b"
              type="submit"
              onClick={() => handleSubmit}
              containerStyle={{ marginBottom: '25px' }}
            >
              Cadastrar
            </Button>
          </Form>
        ) : (
          <Form ref={formRef2} onSubmit={handleSubmit2}>
            <InfoContainer>
              <LeftContainer delay={0.1}>
                {at.tscod !== 'JA' && (
                  <>
                    <span>
                      <p>Zonal:</p>
                      <SelectV2
                        name="zon"
                        content={comboZons}
                        initial={init.zon}
                        onChange={handleZONSelect}
                      />
                    </span>
                    <span>
                      <p>Nacional:</p>
                      <SelectV2
                        name="nac"
                        content={comboNacs}
                        initial={init.anb}
                        onChange={handleNACSelect}
                      />
                    </span>
                    <span>
                      <p>Local:</p>
                      <SelectV2
                        name="loc"
                        content={comboLocs}
                        initial={init.loc}
                        onChange={handleLOCSelect}
                      />
                    </span>
                  </>
                )}
                <span>
                  <p>Guia:</p>
                  <SelectV2
                    name="guia"
                    initial={init.label}
                    content={comboGuias}
                  />
                </span>
              </LeftContainer>
            </InfoContainer>

            <Button
              bgcolor="#00802b"
              type="submit"
              onClick={() => handleSubmit2}
              containerStyle={{ marginBottom: '50px' }}
            >
              Cadastrar
            </Button>
          </Form>
        )}
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default ParticipantesInsert;
