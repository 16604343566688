const localStorageItems = [
  `${process.env.REACT_APP_SERVICO}`,
  `${process.env.REACT_APP_ATIVIDADE}`,
  `${process.env.REACT_APP_SOLIC}`,
  `${process.env.REACT_APP_PEDIDO}`,
  `${process.env.REACT_APP_DM}`,
  `${process.env.REACT_APP_RM}`,
  `${process.env.REACT_APP_CE}`,
  `${process.env.REACT_APP_GD}`,
  `${process.env.REACT_APP_FPG}`,
  `${process.env.REACT_APP_MB}`,
  `${process.env.REACT_APP_APLIC}`,
  `${process.env.REACT_APP_CPR}`,
  `${process.env.REACT_APP_FINRM}`,
  `${process.env.REACT_APP_FINGD}`,
  `${process.env.REACT_APP_FINCE}`,
  `${process.env.REACT_APP_F2MES}`,
  `${process.env.REACT_APP_DMG}`,
  `${process.env.REACT_APP_DUG}`,
  `${process.env.REACT_APP_DUGXLS}`,
  `${process.env.REACT_APP_DMGXLS}`,
  `${process.env.REACT_APP_HISTENV}`,
  `${process.env.REACT_APP_HISTREC}`,
  `${process.env.REACT_APP_CNTRNUM}`,
  `${process.env.REACT_APP_CNTRNUM_OOC}`,
  `${process.env.REACT_APP_OFFER}`,
  `${process.env.REACT_APP_PATRIMONIO}`,
  `${process.env.REACT_APP_SEMESTRAIS}`,
  `${process.env.REACT_APP_PLANEJAMENTO}`,
  `${process.env.REACT_APP_GTRANSF}`,
  `${process.env.REACT_APP_ANIVERSARIANTES}`,
  `${process.env.REACT_APP_EPG}`,
  `${process.env.REACT_APP_ELEICAO}`,
  `${process.env.REACT_APP_GEVANG}`,
  `${process.env.REACT_APP_PRES_RE}`,
  `${process.env.REACT_APP_BIBLIOTECA}`,
  `${process.env.REACT_APP_CRONOGRAMA_ANUAL}`,
  `${process.env.REACT_APP_CRONOGRAMA_MENSAL}`,
  `${process.env.REACT_APP_RELATFINEVEN}`,
  `${process.env.REACT_APP_TETO}`,
  `${process.env.REACT_APP_MDE}`,
  `${process.env.REACT_APP_LOC}`,
  `${process.env.REACT_APP_MANUAIS}`,
  `${process.env.REACT_APP_GUIAS}`,
  `${process.env.REACT_APP_SUPPORT}`,
];

export default localStorageItems;
