import Nacionais from 'pages/sgo/Secretaria/Nacionais/main';
import NacionaisConsult from 'pages/sgo/Secretaria/Nacionais/consult';
import NacionaisInsert from 'pages/sgo/Secretaria/Nacionais/insert';
import NacionaisUpdate from 'pages/sgo/Secretaria/Nacionais/update';
import NacionaisTeam from 'pages/sgo/Secretaria/Nacionais/team';

import Locais from 'pages/sgo/Secretaria/Locais/main';
import LocaisConsult from 'pages/sgo/Secretaria/Locais/consult';
import LocaisInsert from 'pages/sgo/Secretaria/Locais/insert';
import LocaisUpdate from 'pages/sgo/Secretaria/Locais/update';
import LocaisTeam from 'pages/sgo/Secretaria/Locais/team';

import LocalServ from 'pages/sgo/Secretaria/LocalServ/main';
import LocalServInsert from 'pages/sgo/Secretaria/LocalServ/insert';
import LocalServUpdate from 'pages/sgo/Secretaria/LocalServ/update';

import Atividades from 'pages/sgo/Secretaria/Atividades/main';

import AtividadesInsert from 'pages/sgo/Secretaria/Atividades/insert';
import AtividadesUpdate from 'pages/sgo/Secretaria/Atividades/update';
import AtividadesParticipantes from 'pages/sgo/Secretaria/Atividades/Participantes/main';
import AtividadesEncerramento from 'pages/sgo/Secretaria/Atividades/encerramento';
import ParticipantesInsert from 'pages/sgo/Secretaria/Atividades/Participantes/insert';
import ParticipantesUpdate from 'pages/sgo/Secretaria/Atividades/Participantes/update';

import Guias from 'pages/sgo/Secretaria/Guias/main';
import Avatar from 'pages/sgo/Secretaria/Guias/avatar';
import GuiasInsert from 'pages/sgo/Secretaria/Guias/insert';
import GuiasUpdate from 'pages/sgo/Secretaria/Guias/update';

import DMG from 'pages/sgo/Secretaria/DMG/main';
import DMGGuias from 'pages/sgo/Secretaria/DMG/guias';
import DMGInsert from 'pages/sgo/Secretaria/DMG/insert';
import DMGUpdate from 'pages/sgo/Secretaria/DMG/update';
import DMGRelatorio from 'pages/sgo/Secretaria/DMG/relatorio';

import DUG from 'pages/sgo/Secretaria/DUG/main';
import DUGInsert from 'pages/sgo/Secretaria/DUG/insert';
import DUGGuias from 'pages/sgo/Secretaria/DUG/guias';
import DUGUpdate from 'pages/sgo/Secretaria/DUG/update';
import DUGRelatorio from 'pages/sgo/Secretaria/DUG/relatorio';

import CntrNumMenu from 'pages/sgo/Secretaria/CntrNum/menu';
import CntrNum from 'pages/sgo/Secretaria/CntrNum/main';
import CntrNumOOC from 'pages/sgo/Secretaria/CntrNum/ooc';
import CntrNumSearch from 'pages/sgo/Secretaria/CntrNum/search';
import CntrNumInsert from 'pages/sgo/Secretaria/CntrNum/insert';
import CntrNumTransf from 'pages/sgo/Secretaria/CntrNum/transf';

import FIGMenu from 'pages/sgo/Secretaria/FIG/main';
import FIGOutras from 'pages/sgo/Secretaria/FIG/outras';
import FIGDialogo from 'pages/sgo/Secretaria/FIG/dialogo';
import FIGTraj from 'pages/sgo/Secretaria/FIG/trajetoria';
import FIGFunc from 'pages/sgo/Secretaria/FIG/funcoes';
import FIGServ from 'pages/sgo/Secretaria/FIG/servicos';
import FIGAssist from 'pages/sgo/Secretaria/FIG/assistencias/main';
import FIGAssistInc from 'pages/sgo/Secretaria/FIG/assistencias/insert';
import FIGAssistUp from 'pages/sgo/Secretaria/FIG/assistencias/update';
import FIGHistoria from 'pages/sgo/Secretaria/FIG/historia/main';

import EPG from 'pages/sgo/Secretaria/EPG/main';
import EPGStages from 'pages/sgo/Secretaria/EPG/stages';
import EPGInsert from 'pages/sgo/Secretaria/EPG/insert';
import EPGUpdate from 'pages/sgo/Secretaria/EPG/update';
import EPGAlunos from 'pages/sgo/Secretaria/EPG/Alunos/main';
import EPGAlunosInsert from 'pages/sgo/Secretaria/EPG/Alunos/insert';
import EPGAlunosRegister from 'pages/sgo/Secretaria/EPG/Alunos/register';
import EPGAlunosUpdate from 'pages/sgo/Secretaria/EPG/Alunos/update';
import EPGAlunosTransfer from 'pages/sgo/Secretaria/EPG/Alunos/transfer';

import Eleicao from 'pages/sgo/Secretaria/Eleicao/main';
import EleicaoVote from 'pages/sgo/Secretaria/Eleicao/vote';
import EleicaoCompList from 'pages/sgo/Secretaria/Eleicao/comprovante/main';
import EleicaoCompItem from 'pages/sgo/Secretaria/Eleicao/comprovante/consult';

import Equipes from 'pages/sgo/Secretaria/Equipes/main';

import GEvang from 'pages/sgo/Secretaria/Evangelizadores/main';
import GEvangMore from 'pages/sgo/Secretaria/Evangelizadores/more';
import GEvangInsert from 'pages/sgo/Secretaria/Evangelizadores/insert';

import Biblioteca from 'pages/sgo/Secretaria/Biblioteca/main';
import BibliotecaInsert from 'pages/sgo/Secretaria/Biblioteca/insert';
import BibliotecaUpdate from 'pages/sgo/Secretaria/Biblioteca/update';

import CronogramaMenu from 'pages/sgo/Secretaria/Cronograma/main';
import Cronograma from 'pages/sgo/Secretaria/Cronograma/content';

import ManualGuia from 'pages/sgo/Secretaria/ManualGuia/main';

import Oracoes from 'pages/sgo/Secretaria/Oracoes';

import Documents from 'pages/sgo/Secretaria/Documentos/main';

import Summaries from 'pages/sgo/Secretaria/ResumoEstatistico';

// import DEV from 'pages/DEV';
import Page404 from 'pages/404';

const SRoutes = [
  { path: '/nacionais', isPrivate: true, component: Nacionais },
  { path: '/nacionais/consult', isPrivate: true, component: NacionaisConsult },
  { path: '/nacionais/insert', isPrivate: true, component: NacionaisInsert },
  { path: '/nacionais/update', isPrivate: true, component: NacionaisUpdate },
  { path: '/nacionais/team', isPrivate: true, component: NacionaisTeam },
  { path: '/locais', isPrivate: true, component: Locais },
  { path: '/locais/consult', isPrivate: true, component: LocaisConsult },
  { path: '/locais/insert', isPrivate: true, component: LocaisInsert },
  { path: '/locais/update', isPrivate: true, component: LocaisUpdate },
  { path: '/locais/team', isPrivate: true, component: LocaisTeam },
  { path: '/localserv', isPrivate: true, component: LocalServ },
  { path: '/localserv/insert', isPrivate: true, component: LocalServInsert },
  { path: '/localserv/update', isPrivate: true, component: LocalServUpdate },

  { path: '/guias', isPrivate: true, component: Guias },
  { path: '/guias/insert', isPrivate: true, component: GuiasInsert },
  { path: '/guias/update', isPrivate: true, component: GuiasUpdate },
  { path: '/guias/avatar', isPrivate: true, component: Avatar },
  { path: '/dmg', isPrivate: true, component: DMG },
  { path: '/dmg/guias', isPrivate: true, component: DMGGuias },
  { path: '/dmg/insert', isPrivate: true, component: DMGInsert },
  { path: '/dmg/update', isPrivate: true, component: DMGUpdate },
  { path: '/dmg/relatorio', isPrivate: true, component: DMGRelatorio },
  { path: '/dug', isPrivate: true, component: DUG },
  { path: '/dug/insert', isPrivate: true, component: DUGInsert },
  { path: '/dug/guias', isPrivate: true, component: DUGGuias },
  { path: '/dug/update', isPrivate: true, component: DUGUpdate },
  { path: '/dug/relatorio', isPrivate: true, component: DUGRelatorio },
  {
    path: '/materiais_numerados/menu',
    isPrivate: true,
    component: CntrNumMenu,
  },
  {
    path: '/materiais_numerados',
    isPrivate: true,
    component: CntrNum,
  },
  {
    path: '/materiais_numerados/fora_de_circulacao',
    isPrivate: true,
    component: CntrNumOOC,
  },
  {
    path: '/materiais_numerados/localizar',
    isPrivate: true,
    component: CntrNumSearch,
  },
  {
    path: '/materiais_numerados/insert',
    isPrivate: true,
    component: CntrNumInsert,
  },
  {
    path: '/materiais_numerados/transferencia',
    isPrivate: true,
    component: CntrNumTransf,
  },
  { path: '/fig', isPrivate: true, component: FIGMenu },
  { path: '/fig/outras_atividades', isPrivate: true, component: FIGOutras },
  { path: '/fig/historia', isPrivate: true, component: FIGHistoria },

  { path: '/fig/servicos', isPrivate: true, component: FIGServ },
  { path: '/fig/assistencias', isPrivate: true, component: FIGAssist },
  {
    path: '/fig/assistencias/insert',
    isPrivate: true,
    component: FIGAssistInc,
  },
  {
    path: '/fig/assistencias/update',
    isPrivate: true,
    component: FIGAssistUp,
  },
  { path: '/fig/dialogo_fraterno', isPrivate: true, component: FIGDialogo },
  { path: '/fig/funcoes', isPrivate: true, component: FIGFunc },
  { path: '/fig/trajetoria', isPrivate: true, component: FIGTraj },

  { path: '/epg', isPrivate: true, component: EPG },
  { path: '/epg/insert', isPrivate: true, component: EPGInsert },
  { path: '/epg/update', isPrivate: true, component: EPGUpdate },
  { path: '/epg/etapas', isPrivate: true, component: EPGStages },

  { path: '/epg/etapas/futuros_guias', isPrivate: true, component: EPGAlunos },
  {
    path: '/epg/etapas/futuros_guias/insert',
    isPrivate: true,
    component: EPGAlunosInsert,
  },
  {
    path: '/epg/etapas/futuros_guias/register',
    isPrivate: true,
    component: EPGAlunosRegister,
  },
  {
    path: '/epg/etapas/futuros_guias/update',
    isPrivate: true,
    component: EPGAlunosUpdate,
  },
  {
    path: '/epg/etapas/futuros_guias/transfer',
    isPrivate: true,
    component: EPGAlunosTransfer,
  },
  { path: '/eleicao', isPrivate: true, component: Eleicao },
  { path: '/eleicao/votar', isPrivate: true, component: EleicaoVote },
  {
    path: '/eleicao/comprovantes',
    isPrivate: true,
    component: EleicaoCompList,
  },
  {
    path: '/eleicao/comprovante',
    isPrivate: true,
    component: EleicaoCompItem,
  },
  { path: '/equipes', isPrivate: true, component: Equipes },
  { path: '/coordenadores_curso_casais', isPrivate: true, component: GEvang },
  { path: '/coordenadores_curso_casais/more', isPrivate: true, component: GEvangMore },
  { path: '/coordenadores_curso_casais/insert', isPrivate: true, component: GEvangInsert },
  { path: '/evangelizadores', isPrivate: true, component: GEvang },
  { path: '/evangelizadores/more', isPrivate: true, component: GEvangMore },
  { path: '/evangelizadores/insert', isPrivate: true, component: GEvangInsert },
  // { path: '/biblioteca', isPrivate: true, component: DEV },
  // { path: '/biblioteca/insert', isPrivate: true, component: DEV },
  // { path: '/biblioteca/update', isPrivate: true, component: DEV },
  { path: '/biblioteca', isPrivate: true, component: Biblioteca },
  { path: '/biblioteca/insert', isPrivate: true, component: BibliotecaInsert },
  { path: '/biblioteca/update', isPrivate: true, component: BibliotecaUpdate },

  { path: '/manuais_guias', isPrivate: true, component: ManualGuia },

  { path: '/oracoes', isPrivate: true, component: Oracoes },
  { path: '/documentos-digitais', isPrivate: true, component: Documents },

  // { path: '/manuais_guias', isPrivate: true, component: DEV },
  // { path: '/cronograma/menu', isPrivate: true, component: DEV },
  { path: '/cronograma/menu', isPrivate: true, component: CronogramaMenu },
  { path: '/cronograma/:mode', isPrivate: true, component: Cronograma },
  // { path: '/cronograma/:mode', isPrivate: true, component: DEV },

  { path: '/resumo_estatistico', isPrivate: true, component: Summaries },
  // #DEV

  // { path: '/mensagem', isPrivate: true, component: DEV },
  // { path: '/agenda', isPrivate: true, component: DEV },

  { path: '/not-found', isPrivate: true, component: Page404 },
  { path: '/:as', isPrivate: true, component: Atividades },
  { path: '/:as/insert', isPrivate: true, component: AtividadesInsert },
  { path: '/:as/update', isPrivate: true, component: AtividadesUpdate },
  {
    path: '/:as/encerramento',
    isPrivate: true,
    component: AtividadesEncerramento,
  },
  {
    path: '/:as/participantes',
    isPrivate: true,
    component: AtividadesParticipantes,
  },
  {
    path: '/:as/participantes/insert',
    isPrivate: true,
    component: ParticipantesInsert,
  },
  {
    path: '/:as/participantes/update',
    isPrivate: true,
    component: ParticipantesUpdate,
  },
  // {
  //   path: `/atividades`,
  //   isPrivate: true,
  //   component: Atividades,
  // },
  // {
  //   path: `/servicos`,
  //   isPrivate: true,
  //   component: Atividades,
  // },

  // { path: '/atividades/insert', isPrivate: true, component: AtividadesInsert },
  // { path: '/atividades/update', isPrivate: true, component: AtividadesUpdate },
  // {
  //   path: '/atividades/participantes',
  //   isPrivate: true,
  //   component: AtividadesParticipantes,
  // },
  // {
  //   path: '/atividades/participantes/insert',
  //   isPrivate: true,
  //   component: ParticipantesInsert,
  // },
  // {
  //   path: '/atividades/participantes/update',
  //   isPrivate: true,
  //   component: ParticipantesUpdate,
  // },
];

export default SRoutes;
