import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';

import SGOHeader from 'components/SGOHeader';
import SGONavbar from 'components/SGONavbar';
import SGOFooter from 'components/SGOFooter';
import Loading from 'components/Loading';
import ScrollTop from 'components/ScrollTop';
import SelectV2 from 'components/SelectV2';

import { useAuth } from 'hooks/auth';
import { useCredentials } from 'hooks/credentials';
import { useWindow } from 'hooks/window';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { FaPlusCircle, FaSearch, FaTimesCircle } from 'react-icons/fa';

import * as D from 'styles/dialog_delete';
import { DeleteProps } from 'styles/dialog_delete';

import api from 'services/api';

import {
  Container,
  Content,
  AlteredHeader,
  ComboProps,
  ComboLocalProps,
  CoordProps,
  RemoveButton,
  IncButton,
} from 'styles/sgo_wrappers';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import {
  getLocalStorage,
  setLocalStorage,
  deleteLocalStorageItemKey,
} from 'utils/handleLocalStorage';

import { useSpring } from 'react-spring';
import { FaTimes } from 'react-icons/fa';
import { removeAcento } from 'utils/specialChars';
import {
  AlteredFloatInput,
  TableWrapper,
  Table,
  SubtitleContainer,
} from './styles';

interface ComponentProps {
  name: string;
  cert: string;
  avatar: string;
  cidade: string;
  uf: string;
  phoneres: string;
  phonecel: string;
  mail: string;
}

export interface ListProps {
  seq: string;
  zon: string;
  zond: string;
  nac: string;
  nacd: string;
  loc: string;
  locd: string;
  type: string;
  g1: ComponentProps;
  g2: ComponentProps;
  gnames: string;
  guias: string;
  init: string;
  through: string;
  servs: string;
  ofics: string;
}

interface LocalStorageProps {
  zon?: string;
  zond?: string;
  anb?: string;
  anbd?: string;
  loc?: string;
  locd?: string;
  evang?: string;
  evangd?: string;
  cod?: string;
  desc?: string;
  filterBy?: string;
}

const GEvang: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { width } = useWindow();
  const { pathname } = useLocation();
  const { errorHandling } = useCredentials();
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [rawList, setRawList] = useState<ListProps[]>([]);
  const [list, setList] = useState<ListProps[]>([]);
  const [selected, setSelected] = useState<ListProps>({} as ListProps);
  const [isCC] = useState(() => {
    return pathname.includes('coordenadores_curso_casais');
  });

  const [localStorageKey] = useState(() => {
    const { REACT_APP_GEVANG, REACT_APP_GEVANG_CC } = process.env;
    if (REACT_APP_GEVANG && REACT_APP_GEVANG_CC) {
      return isCC ? REACT_APP_GEVANG_CC : REACT_APP_GEVANG;
    }

    return '';
  });

  const [deleteDiag, setDeleteDiag] = useState<DeleteProps>({} as DeleteProps);

  const [type, setType] = useState(() => {
    const { filterBy }: LocalStorageProps = getLocalStorage(localStorageKey);

    return (
      filterBy ||
      (['INT', 'ZON'].indexOf(user.perfil) > -1
        ? 'zon'
        : user.perfil === 'NAC'
        ? 'nac'
        : 'loc')
    );
  });

  const [initialZon] = useState(() => {
    const { zon }: LocalStorageProps = getLocalStorage(localStorageKey);

    return zon || (user.perfil === 'INT' ? user.zoncod : 'Selecione');
  });
  const [initialNac, setInitialNac] = useState(() => {
    const { anb }: LocalStorageProps = getLocalStorage(localStorageKey);

    return anb || 'Selecione';
  });
  const [initialLoc, setInitialLoc] = useState(() => {
    const { loc }: LocalStorageProps = getLocalStorage(localStorageKey);

    return loc || 'Selecione';
  });
  const [initialEvang, setInitialEvang] = useState(() => {
    const { evang }: LocalStorageProps = getLocalStorage(localStorageKey);

    return evang || 'Selecione';
  });

  const [tevang, setTEvang] = useState<CoordProps>(() => {
    const { evang, evangd }: LocalStorageProps = getLocalStorage(
      localStorageKey,
    );

    return { cod: evang || '', desc: evangd || 'Guias Evangelizadores' };
  });

  const [coord, setCoord] = useState<CoordProps>(() => {
    const { cod, desc }: LocalStorageProps = getLocalStorage(localStorageKey);
    return cod && desc
      ? { cod, desc }
      : ['INT', 'ZON'].indexOf(user.perfil) > -1
      ? { cod: user.zoncod, desc: user.zondesc }
      : user.perfil === 'NAC'
      ? { cod: user.anbc, desc: user.anbdesc }
      : { cod: user.loccod, desc: user.locdesc };
  });

  const [comboZons, setComboZons] = useState<ComboProps[]>([]);
  const [nacs, setNacs] = useState<ComboProps[]>([]);
  const [comboNacs, setComboNacs] = useState<ComboProps[]>([]);
  const [locs, setLocs] = useState<ComboLocalProps[]>([]);
  const [comboLocs, setComboLocs] = useState<ComboProps[]>([]);
  const [comboEvang] = useState<ComboProps[]>(() => {
    return isCC
      ? []
      : [
          { value: 'AR', label: 'Animador no Espírito' },
          // { value: 'CC', label: 'Guia Casal Coordenador CURSO para CASAIS' },
          { value: 'CE', label: 'Guia Casal Evangelizador EED' },
        ];
  });
  const [searchValue, setSearchValue] = useState('');

  const getComboZON = useCallback(async () => {
    const response = await api.get('/combos/comboZONs.php');
    setComboZons(response.data);
  }, []);

  const getComboANB = useCallback(async () => {
    const response = await api.get('/combos/comboANBs.php');
    setNacs(response.data);

    const { zon }: LocalStorageProps = getLocalStorage(localStorageKey);

    setComboNacs(
      response.data.filter((item: ComboProps) =>
        zon
          ? item.value.substring(0, 2) === zon.substring(0, 2)
          : item.value.substring(0, 2) === user.zoncod.substring(0, 2),
      ),
    );
  }, [localStorageKey, user.zoncod]);

  const getComboLOC = useCallback(async () => {
    const response = await api.get(
      // `/combos/comboLOCs.php`,
      `/combos/comboLOCs.php?data=${JSON.stringify({ filterStat: true })}`,
    );
    setLocs(response.data);

    const { anb }: LocalStorageProps = getLocalStorage(localStorageKey);

    if (user.perfil === 'NAC' || !!anb) {
      setComboLocs(
        response.data.filter(
          (item: ComboLocalProps) => item.anb === (anb || user.anbc),
        ),
      );
    }
  }, [localStorageKey, user.anbc, user.perfil]);

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/sgo/gevang_list.php', {
        headers: {
          calltype: isCC ? 'cc' : 'evang',
        },
      });

      const initialFilter = response.data.filter((item: ListProps) =>
        isCC ? item.type === 'CC' : item.type !== 'CC',
      );

      setRawList(() =>
        initialFilter.map((item: ListProps) => ({
          ...item,
          gnames: `${item.g1.name}${item.g2.name ? ` e ${item.g2.name}` : ''}`,
        })),
      );

      let filtered = initialFilter
        .filter(
          (item: ListProps) =>
            coord.cod ===
            (type === 'zon' ? item.zon : type === 'nac' ? item.nac : item.loc),
        )
        .map((item: ListProps) => ({
          ...item,
          gnames: `${item.g1.name}${item.g2.name ? ` e ${item.g2.name}` : ''}`,
        }));

      if (tevang.cod) {
        filtered = filtered.filter(
          (item: ListProps) => item.type === tevang.cod,
        );
      }

      setList(filtered);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandling, isCC]);

  useEffect(() => {
    getList();
    getComboZON();
    getComboANB();
    getComboLOC();
  }, [getComboANB, getComboLOC, getComboZON, getList]);

  const handleZONselect = useCallback(() => {
    setSearchValue('');
    const zon = formRef.current?.getFieldValue('comboZon');
    const index = comboZons.findIndex((item) => item.value === zon);
    setInitialNac('Selecione');
    setInitialLoc('Selecione');
    formRef.current?.setFieldValue('comboLoc', '');
    formRef.current?.setFieldValue('comboNac', '');

    setComboNacs(
      nacs.filter((item) => item.value.substring(0, 2) === zon.substring(0, 2)),
    );
    setComboLocs([]);

    const toSet = {
      cod: comboZons[index].value,
      desc: comboZons[index].label,
    };
    setCoord({ ...toSet });
    setType('zon');

    setLocalStorage(localStorageKey, {
      zon: toSet.cod,
      zond: toSet.desc,
      filterBy: 'zon',
      ...toSet,
    });

    deleteLocalStorageItemKey(localStorageKey, ['anb', 'anbd', 'loc', 'locd']);

    let filtered = rawList.filter((item) => item.zon === zon);

    if (tevang.cod) {
      filtered = filtered.filter((item) => item.type === tevang.cod);
    }
    setList(filtered);
  }, [comboZons, localStorageKey, nacs, rawList, tevang.cod]);

  const handleANBselect = useCallback(() => {
    setSearchValue('');
    const anb = formRef.current?.getFieldValue('comboNac');
    const index = comboNacs.findIndex((item) => item.value === anb);

    setComboLocs(locs.filter((item) => item.anb === anb));
    formRef.current?.setFieldValue('comboLoc', '');

    deleteLocalStorageItemKey(localStorageKey, ['loc', 'locd']);

    const toSet = {
      cod: comboNacs[index].value,
      desc: comboNacs[index].label,
    };
    setCoord({ ...toSet });
    setType('nac');

    setLocalStorage(localStorageKey, {
      anb: toSet.cod,
      anbd: toSet.desc,
      filterBy: 'nac',
      ...toSet,
    });

    let filtered = rawList.filter((item) => item.nac === anb);

    if (tevang.cod) {
      filtered = filtered.filter((item) => item.type === tevang.cod);
    }
    setList(filtered);
  }, [comboNacs, localStorageKey, locs, rawList, tevang.cod]);

  const handleLOCselect = useCallback(() => {
    setSearchValue('');
    const loc = formRef.current?.getFieldValue('comboLoc');
    const index = comboLocs.findIndex((item) => item.value === loc);

    const toSet = {
      cod: comboLocs[index].value,
      desc: comboLocs[index].label,
    };
    setCoord({ ...toSet });
    setType('loc');

    setLocalStorage(localStorageKey, {
      loc: toSet.cod,
      locd: toSet.desc,
      filterBy: 'loc',
      ...toSet,
    });

    let filtered = rawList.filter((item) => item.loc === loc);

    if (tevang.cod) {
      filtered = filtered.filter((item) => item.type === tevang.cod);
    }
    setList(filtered);
  }, [comboLocs, localStorageKey, rawList, tevang.cod]);

  const handleEvangSelect = useCallback(() => {
    setSearchValue('');
    const ge = formRef.current?.getFieldValue('comboEvang');
    const index = comboEvang.findIndex((item) => item.value === ge);

    setTEvang({ cod: comboEvang[index].value, desc: comboEvang[index].label });

    setLocalStorage(localStorageKey, {
      evang: comboEvang[index].value,
      evangd: comboEvang[index].label,
    });
    setList(
      rawList.filter(
        (item) =>
          coord.cod ===
            (type === 'zon'
              ? item.zon
              : type === 'nac'
              ? item.nac
              : item.loc) && item.type === ge,
      ),
    );
  }, [comboEvang, localStorageKey, rawList, coord.cod, type]);

  const handleClearLocFilter = useCallback(() => {
    setSearchValue('');
    const anb =
      user.perfil === 'NAC'
        ? user.anbc
        : formRef.current?.getFieldValue('comboNac');

    const index = nacs.findIndex((item) => item.value === anb);
    setInitialLoc('Selecione');
    formRef.current?.setFieldValue('comboLoc', '');

    const toSet = {
      cod: nacs[index].value,
      desc: nacs[index].label,
    };
    setType('nac');
    setCoord({ ...toSet });
    setLocalStorage(localStorageKey, {
      ...toSet,
      filterBy: 'nac',
    });

    deleteLocalStorageItemKey(localStorageKey, ['loc', 'locd']);
    setList(rawList.filter((item) => item.nac === anb));

    let filtered = rawList.filter((item) => item.nac === anb);

    if (tevang.cod) {
      filtered = filtered.filter((item) => item.type === tevang.cod);
    }
    setList(filtered);
  }, [localStorageKey, nacs, rawList, tevang.cod, user.anbc, user.perfil]);

  const handleClearNacFilter = useCallback(() => {
    handleClearLocFilter();
    setSearchValue('');
    const zon =
      user.perfil === 'ZON'
        ? user.zoncod
        : formRef.current?.getFieldValue('comboZon');

    const index = comboZons.findIndex((item) => item.value === zon);
    setInitialNac('Selecione');
    setComboLocs([]);
    formRef.current?.setFieldValue('comboNac', '');

    const toSet = {
      cod: comboZons[index].value,
      desc: comboZons[index].label,
    };

    setCoord({ ...toSet });
    setType('zon');
    setLocalStorage(localStorageKey, {
      ...toSet,
      filterBy: 'zon',
    });

    deleteLocalStorageItemKey(localStorageKey, ['anb', 'anbd']);
    setList(rawList.filter((item) => item.zon === zon));

    let filtered = rawList.filter((item) => item.zon === zon);

    if (tevang.cod) {
      filtered = filtered.filter((item) => item.type === tevang.cod);
    }
    setList(filtered);
  }, [
    comboZons,
    handleClearLocFilter,
    localStorageKey,
    rawList,
    tevang.cod,
    user.perfil,
    user.zoncod,
  ]);

  const handleClearGEFilter = useCallback(() => {
    setInitialEvang('Selecione');
    setSearchValue('');
    setTEvang({ cod: '', desc: 'Guias Evangelizadores' });
    formRef.current?.setFieldValue('comboEvang', '');

    deleteLocalStorageItemKey(localStorageKey, ['evang', 'evangd']);

    setList(
      rawList.filter(
        (item) =>
          coord.cod ===
          (type === 'zon' ? item.zon : type === 'nac' ? item.nac : item.loc),
      ),
    );
  }, [coord.cod, localStorageKey, rawList, type]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);

      let filtered = rawList
        .filter(
          (item: ListProps) =>
            coord.cod ===
            (type === 'zon' ? item.zon : type === 'nac' ? item.nac : item.loc),
        )
        .map((item: ListProps) => ({
          ...item,
          gnames: `${item.g1.name}${item.g2.name ? ` e ${item.g2.name}` : ''}`,
        }));

      if (tevang.cod) {
        filtered = filtered.filter((item) => item.type === tevang.cod);
      }

      filtered = filtered.filter((item) =>
        removeAcento(item.gnames)
          .toLowerCase()
          .includes(removeAcento(value).toLowerCase()),
      );

      setList(filtered);
    },
    [coord.cod, rawList, tevang.cod, type],
  );

  const handlePreInc = useCallback(() => {
    history.push(`${pathname}/insert`);
  }, [history, pathname]);

  const handlePreDel = useCallback(
    (value: string) => {
      const index = list.findIndex((item) => item.seq === value);

      setSelected({ ...list[index] });
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
    },
    [list],
  );

  const handleCancelDel = useCallback(() => {
    setDeleteDiag((state) => ({ ...state, open: !state.open }));

    setTimeout(() => {
      setSelected({} as ListProps);
    }, 100);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          seq: selected.seq,
        }),
      );
      setDeleteDiag((state) => ({ ...state, open: !state.open }));
      await api.post('/sgo/gevang_delete.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setRawList((state) => state.filter((item) => item.seq !== selected.seq));
      setList((state) => state.filter((item) => item.seq !== selected.seq));

      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandling(err);
    }
  }, [errorHandling, selected.seq]);

  const locRemoveButton = useSpring({
    opacity: formRef.current?.getFieldValue('comboLoc') ? 1 : 0,
    pointerEvents: formRef.current?.getFieldValue('comboLoc') ? 'all' : 'none',
    height: formRef.current?.getFieldValue('comboLoc') ? '22px' : '0px',
  });

  const nacRemoveButton = useSpring({
    opacity: formRef.current?.getFieldValue('comboNac') ? 1 : 0,
    pointerEvents: formRef.current?.getFieldValue('comboNac') ? 'all' : 'none',
    height: formRef.current?.getFieldValue('comboNac') ? '22px' : '0px',
  });

  const geRemoveButton = useSpring({
    opacity: tevang.cod ? 1 : 0,
    pointerEvents: tevang.cod ? 'all' : 'none',
    height: tevang.cod ? '22px' : '0px',
  });

  return (
    <Container>
      <Loading isLoading={loading} />
      <ScrollTop />
      <SGOHeader />
      <SGONavbar
        needFilter={isCC ? !['LOC', 'GUI'].includes(user.perfil) : true}
        filterContent={
          <Form ref={formRef} onSubmit={() => null}>
            <div>
              {user.perfil === 'INT' && (
                <span>
                  <p>Filtre por Zonal:</p>
                  <SelectV2
                    name="comboZon"
                    content={comboZons}
                    onChange={handleZONselect}
                    initial={initialZon}
                  />
                </span>
              )}
              {['INT', 'ZON'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Nacional:</p>
                  <SelectV2
                    name="comboNac"
                    content={comboNacs}
                    onChange={handleANBselect}
                    initial={initialNac}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleClearNacFilter}
                    style={nacRemoveButton}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {['INT', 'ZON', 'NAC'].indexOf(user.perfil) > -1 && (
                <span>
                  <p>Filtre por Local:</p>
                  <SelectV2
                    name="comboLoc"
                    content={comboLocs}
                    onChange={handleLOCselect}
                    initial={initialLoc}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleClearLocFilter}
                    style={locRemoveButton}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
              {!isCC && (
                <span>
                  <p>Tipo de Evangelizador:</p>
                  <SelectV2
                    name="comboEvang"
                    content={comboEvang}
                    onChange={handleEvangSelect}
                    initial={initialEvang}
                  />
                  <RemoveButton
                    type="button"
                    onClick={handleClearGEFilter}
                    style={geRemoveButton}
                  >
                    <FaTimes />
                    &nbsp;
                    <p>Excluir filtro</p>
                  </RemoveButton>
                </span>
              )}
            </div>
          </Form>
        }
      />
      <Content>
        <AlteredHeader>
          <div>
            <p>
              {isCC ? `Guia Casal Coordenador CURSO para CASAIS` : tevang.desc}
            </p>
          </div>
          <div>
            <p>{coord.desc}</p>
          </div>
        </AlteredHeader>

        <AlteredFloatInput>
          <input
            placeholder="Filtrar por Guia"
            value={searchValue}
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSearch(e.target.value);
            }}
          />
        </AlteredFloatInput>
        {/* TODO: fix subtitlecontainer CSS */}
        <SubtitleContainer>
          {isCC ? (
            <span>
              <strong>CC:</strong>
              <p>Guia Casal Coordenador CURSO para CASAIS</p>
            </span>
          ) : (
            <>
              <span>
                <strong>CE:</strong>
                <p>Guia Casal Evangelizador EED</p>
              </span>
              <span>
                <strong>AR:</strong>
                <p>Animador no Espírito</p>
              </span>
            </>
          )}
        </SubtitleContainer>
        {(user.perfil === 'ZON' &&
          !pathname.includes('coordenadores_curso_casais')) ||
        (user.perfil === 'NAC' &&
          pathname.includes('coordenadores_curso_casais')) ? (
            <IncButton type="button" onClick={handlePreInc}>
            <p>Novo Registro</p>
            <FaPlusCircle />
          </IncButton>
        ) : null}
        <TableWrapper>
          <Table tabletype={user.perfil} isCC={isCC}>
            <thead>
              <tr>
                {['NAC', 'ZON', 'INT'].indexOf(user.perfil) > -1 && (
                  <>
                    {user.perfil !== 'NAC' && (
                      <td>Nac{width > 500 ? 'ional' : '.'}</td>
                    )}
                    <td>Loc{width > 500 ? 'al' : '.'}</td>
                  </>
                )}

                <td>Guia(s)</td>
                {!isCC && <td>Evang{width > 800 ? 'elizador' : '.'}</td>}

                <td>Con.</td>
                {(isCC && user.perfil === 'NAC') ||
                (!isCC && user.perfil === 'ZON') ? (
                  <td>Exc.</td>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <React.Fragment key={item.seq}>
                  {index !== 0 && index % 10 === 0 && (
                    <tr style={{ background: '#332e2e', color: '#fff' }}>
                      {['NAC', 'ZON', 'INT'].indexOf(user.perfil) > -1 && (
                        <>
                          {user.perfil !== 'NAC' && (
                            <td>Nac{width > 500 ? 'ional' : '.'}</td>
                          )}
                          <td>Loc{width > 500 ? 'al' : '.'}</td>
                        </>
                      )}

                      <td>Guia(s)</td>
                      {!isCC && <td>Evang{width > 800 ? 'elizador' : '.'}</td>}

                      <td>Con.</td>
                      {(isCC && user.perfil === 'NAC') ||
                      (!isCC && user.perfil === 'ZON') ? (
                        <td>Exc.</td>
                      ) : null}
                    </tr>
                  )}
                  <tr
                    style={{
                      background: index % 2 === 0 ? '#e6e6e6' : '#fff',
                    }}
                  >
                    {['NAC', 'ZON', 'INT'].indexOf(user.perfil) > -1 && (
                      <>
                        {user.perfil !== 'NAC' && (
                          <td>{width > 500 ? item.nacd : item.nac}</td>
                        )}
                        <td>{width > 500 ? item.locd : item.loc}</td>
                      </>
                    )}

                    <td>
                      <span>
                        <p>{item.g1.name}</p>
                        <p>{item.g2.name}</p>
                      </span>
                    </td>
                    {!isCC && (
                      <td>
                        {width > 800
                          ? `${
                              item.type === 'AR'
                                ? 'Animador no Espírito'
                                : `Guia Casal ${
                                    item.type === 'CC'
                                      ? 'Coordenador CURSO para CASAIS'
                                      : 'Evangelizador EED'
                                  }`
                            }`
                          : ['LOC', 'GUI'].indexOf(user.perfil) > -1
                          ? width > 620
                            ? `${
                                item.type === 'AR'
                                  ? 'Animador no Espírito'
                                  : `Guia Casal ${
                                      item.type === 'CC'
                                        ? 'Coordenador CURSO para CASAIS'
                                        : 'Evangelizador EED'
                                    }`
                              }`
                            : item.type
                          : item.type}
                      </td>
                    )}
                    <td>
                      <Link
                        to={{
                          pathname: `${pathname}/more`,
                          state: { ...item },
                        }}
                      >
                        <FaSearch />
                      </Link>
                    </td>
                    {((isCC && user.perfil === 'NAC') ||
                      (!isCC && user.perfil === 'ZON')) && (
                      <td>
                        <button
                          type="button"
                          onClick={() => handlePreDel(item.seq)}
                        >
                          <FaTimesCircle />
                        </button>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
        <D.Container scroll="paper" maxWidth={false} open={!!deleteDiag.open}>
          <D.Title>
            <h2>*** ATENÇÃO ***</h2>
          </D.Title>
          <D.Content>
            <D.ModalDeleteContent>
              <p>
                Você está prestes a excluir&nbsp;
                <strong style={{ color: '#c53030' }}>permanentemente</strong>
                &nbsp;o registro de&nbsp;
                {isCC
                  ? 'casal coordenador de curso para casais'
                  : 'evangelizador'}
                &nbsp;de:
              </p>
              <div>
                <span>
                  <p>
                    <strong style={{ fontSize: '18px', color: '#8a0002' }}>
                      {`${
                        selected.type === 'AR'
                          ? 'Animador no Espírito'
                          : `Guia Casal ${
                              selected.type === 'CC'
                                ? 'Coordenador CURSO para CASAIS'
                                : 'Evangelizador EED'
                            }`
                      }`}
                    </strong>
                  </p>
                </span>
                <span>
                  <p>
                    <strong>{selected.gnames}</strong>
                  </p>
                </span>
              </div>
              <p>Se estiver seguro de sua decisão, clique em confirmar.</p>
            </D.ModalDeleteContent>
          </D.Content>
          <D.Actions>
            <D.Cancel type="button" onClick={handleCancelDel}>
              Cancelar
            </D.Cancel>
            <D.Confirm type="button" onClick={handleDelete}>
              Confirmar
            </D.Confirm>
          </D.Actions>
        </D.Container>
      </Content>
      <SGOFooter />
    </Container>
  );
};

export default GEvang;
